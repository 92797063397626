/**
 *
 * App.js
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 *
 */

import { GrowthBookProvider } from '@growthbook/growthbook-react';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Route, Routes } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { get, map } from 'lodash';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Maintenance from 'components/pages/Maintenance';
import AuthenticatedRoute from 'components/AuthenticatedRoute';
import Loader from 'components/base/New/Loader';

import { UserProvider } from 'contexts/UserContext';

import { useInjectSaga } from 'utils/injectSaga';
import { useInjectReducer } from 'utils/injectReducer';

import '../../base.css';

import { Error } from 'components/pages/Error';
import { ApplicationProvider } from 'contexts/ApplicationContext';
import { ApplicationLayout } from 'components/ApplicationLayout';
import { AuthProvider } from 'contexts/AuthContext';
import growthBook, { initializeGrowthBook } from 'utils/growthBook';
import { GrowthBookError } from 'components/pages/GrowthBookError';
import { clearErrorAction, initializeAction } from './actions';

import reducer from './reducer';
import saga from './saga';

import { makeSelectApp } from './selectors';
import { privateRoutes, publicRoutes } from './routeMap';

export function App(props) {
  useInjectReducer({ key: 'App', reducer });
  useInjectSaga({ key: 'App', saga });
  const { clearError, initialize, page } = props;
  const { error, loading, client, user } = page || {};
  const [gbInitializationState, setGbInitializationState] = React.useState('initializing');
  const [gbError, setGbError] = React.useState('');

  useEffect(() => {
    if (get(error, 'graphQLErrors.0.extensions.code') === 'invalid-jwt') {
      window.location.pathname = '/logout';
      clearError();
    }
    if (get(error, 'extensions.code') === 'start-failed') {
      clearError();
    }
  }, [error, clearError]);

  useEffect(() => {
    if (initialize) initialize();
  }, [initialize]);

  useEffect(() => {
    // growthBook.loadFeatures({ autoRefresh: true });
    growthBook.setAttributes({
      url: window.location.href,
      browser: navigator.userAgent,
    });
  }, []);

  React.useEffect(() => {
    setGbInitializationState(() => 'initializing');
    initializeGrowthBook()
      .then(() => {
        setGbInitializationState(() => 'initialized');
      })
      .catch(e => {
        setGbError(e?.message);
        setGbInitializationState(() => 'failed');
      });
    return () => setGbInitializationState(() => 'initializing');
  }, []);

  if (gbInitializationState === 'failed') {
    return <GrowthBookError errorMessage={gbError} />;
  }

  if (process.env.MAINTENANCE_MODE === 'true') {
    return <Maintenance />;
  }

  return (
    <GrowthBookProvider growthbook={growthBook}>
      <AuthProvider>
        <ApplicationProvider>
          <UserProvider client={client} user={user}>
            {loading ? (
              <Loader />
            ) : (
              <Routes>
                {map(publicRoutes, ({ path, component: Component }) => (
                  <Route path={path} element={<Component />} key={path} />
                ))}
                <Route element={<ApplicationLayout />}>
                  {map(privateRoutes, ({ path, component: Component }) => (
                    <Route
                      key={path}
                      path={path}
                      element={
                        <AuthenticatedRoute path={path} error={error}>
                          <Component />
                        </AuthenticatedRoute>
                      }
                      errorElement={<Error error={error} />}
                    />
                  ))}
                </Route>
              </Routes>
            )}
            <ToastContainer style={{ width: 'auto' }} />
          </UserProvider>
        </ApplicationProvider>
      </AuthProvider>
    </GrowthBookProvider>
  );
}

App.propTypes = {
  clearError: PropTypes.func,
  initialize: PropTypes.func,
  page: PropTypes.object,
  //  error: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]),
};

App.defaultProps = {
  page: {},
};

const mapStateToProps = createStructuredSelector({
  page: makeSelectApp(),
});

export function mapDispatchToProps(dispatch) {
  return {
    initialize: () => dispatch(initializeAction()),
    clearError: history => dispatch(clearErrorAction({ history })),
    dispatch,
  };
}

const withConnect = connect(mapStateToProps, mapDispatchToProps);

export default compose(withConnect)(App);
